@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');


body, html {
  background-color: #3d454c;
  width: 100%;
  font-family: 'Josefin Sans';
}


::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}